import React, { Component } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { Loader } from './Loader';

const financial = (x) => Number.parseFloat(x).toFixed(2);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const options = {
  responsive: true,
  plugins: {
    datalabels: {
      display: true,
      color: "black",
      align: "end",
      anchor: "end",
      font: { size: "11" },
      formatter: function(value, context) {
        return Math.round(value*100)/100
      }
    },
    legend: {
      position: 'bottom',
    },
    title: {
      display: true,
      text: 'Sales by Year ' + new Date().getFullYear()
    },
  },
};

export class SalesChart extends Component {
  static displayName = SalesChart.name;

  constructor(props) {
    super(props);
    this.state = { 
        salesData: [],
        loading: true,
        createdAt: ''
    };

    }
    componentDidMount() {
        this.populateSalesData();
    }

    renderSalesData = () => {
        return (
            <div>Hello</div>
        )
    }

  render () {
      let created = this.state.loading
        ? <p>&nbsp;</p>
        : <p className='mt-5 text-muted small'>Data Updated at {this.state.createdAt}</p>

      let chart = this.state.loading
        ? <Loader />
        : <Bar options={options} data={this.state.salesData}/> 

    return (
      <div>
        { chart }
        { created }
      </div>
    );
  }

  populateSalesData = async () => {
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const response = await fetch(`./json/1052-yearly.json`);
    const reciepts = await response.json();
    let createdAt = reciepts.createdAt;
    let labels = [];
    let dataA = [];
    let dataB = [];
    reciepts.thisYear.forEach(e => {
      dataA.push(financial(e.amount))
    });
    reciepts.lastYear.forEach(e => {
      dataB.push(financial(e.amount));
    });
    for (let i = 0; i <= dataA.length-1; i++) {
      labels.push(months[i]);
    }
    this.setState({
        salesData: {
          labels:labels,
          datasets: [
            {
              label: new Date().getFullYear(),
              data: dataA,
              borderColor: 'rgb(255, 0, 0)',
              backgroundColor: "rgba(255, 99, 132, 0.6)",
            },
            {
              label: new Date().getFullYear()-1,
              data: dataB,
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.6)',
            }
          ]
        }
        , loading: false
        , createdAt: createdAt
    });
  }

}
