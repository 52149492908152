import React, { Component } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { Loader } from './Loader';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

export const options = {
  responsive: true,
  plugins: {
    datalabels: {
      display: true,
      color: "black",
      align: "end",
      anchor: "end",
      font: { size: "11" },
      formatter: function(value, context) {
          return value.toFixed(0);
      }
    },
    legend: {
      position: 'bottom',
      display: false
    },
    title: {
      display: true,
      text: 'Sale of the Day ' + new Date().toDateString()
    },
  }
};

export class TodayChart extends Component {
  static displayName = TodayChart.name;

  constructor(props) {
    super(props);
    this.state = { 
        salesData: [],
        loading: true,
        dt: new Date(),
        thisDayTotal: 0,
        thisInvCount: 0,
    };

    }
    componentDidMount() {
        this.populateSalesData();
    }

    renderSalesData = () => {
        return (
            <div>Hello</div>
        )
    }

    financial = (x) => 
                new Intl.NumberFormat('en-UK', {
                style: 'currency',
                currency: 'AED'
              }).format(x);

  render () {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    let chart = this.state.loading
        ? <Loader />
        : <div> 
            <div className='row pb-5'>
              <div className='col col-md-12'>
                  <Bar options={options} data={this.state.salesData}/> 
              </div>
            </div>
            <div className='row p-1 bg-dark text-light'>
              <div className='col col-md-6'>
                Todays Invoice total ({this.state.dt.toDateString()})
              </div>
              <div className='col col-md-6 text-end'>
                { this.financial(this.state.thisDayTotal)}
              </div>
            </div>
            <div className='row p-1 bg-dark text-light'>
              <div className='col col-md-6 text-fz-6'>
                Total { this.state.thisInvCount } Invoices 
              </div>
              <div className='col col-md-6 text-end'>
                
              </div>
            </div>
          </div>
    return (
      <div>
        { chart }
      </div>
    );
  }

  populateSalesData = async () => {
    const response = await fetch(`/json/1052-today.json`);
    const reciepts = await response.json();
    let labels = [];
    let dataA = [];
    reciepts.daySale.forEach(e => {
      dataA.push(e.amount)
      labels.push(e.acName)
    });
    this.setState({
      salesData: {
        labels:labels,
        datasets: [
          {
            label: new Date().toDateString(),
            data: dataA,
            borderWidth: 1,
            borderColor: "rgba(255,99,132,1)",
            backgroundColor: "rgba(255, 99, 132, 0.6)",
          }
        ]
      }
    , loading: false 
    , thisDayTotal: reciepts.dayTotal 
    , thisInvCount: reciepts.dayCount });
  }

}
