import React, { Component } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { Loader } from './Loader';

//const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'November', 'December'];
const dt = new Date();
const financial = (x) => Number.parseFloat(x).toFixed(2);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    datalabels: {
      display: true,
      color: "black",
      align: "end",
      anchor: "end",
      font: { size: "11" },
      formatter: function(value, context) {
        return Math.round(value*100)/100;
      }
    },
    legend: {
      position: 'bottom',
    },
    title: {
      display: true,
      text: 'Sales by Salesman ' + dt.getFullYear()
    },
  },
};

export const optionsP1 = {
  responsive: true,
  plugins: {
    datalabels: {
      display: true,
      color: "black",
      align: "center",
      anchor: "center",
      font: { size: "11" },
      formatter: (value, ctx) => {
        return Math.round(value*100)/100 + '%';
      },
    },
    legend: {
      position: 'bottom',
    },
    title: {
      display: true,
      text: months[dt.getMonth()],
    },
  },
};

export const optionsP2 = {
  responsive: true,
  plugins: {
    datalabels: {
      display: true,
      color: "black",
      align: "center",
      anchor: "center",
      font: { size: "11" },
      formatter: (value, ctx) => {
        return Math.round(value*100)/100 + '%';
      },
    },
    legend: {
      position: 'bottom',
    },
    title: {
      display: true,
      text: months[dt.getMonth()-1],
    },
  },
};
export class SalesmanChart extends Component {
    static displayName = SalesmanChart.name;

  constructor(props) {
    super(props);
    this.state = { 
        salesData: [],
        pieChart1: [],
        pieChart2: [],
        loading: true
    };

    }
    componentDidMount() {
        this.populateSalesData();
    }

  render () {
    let chart = this.state.loading
      ? <Loader />
      : <div>
          <Bar options={options} data={this.state.salesData}/> 
          <div className='row m-5'>
            <div className='col col-lg-6 col-md-12'>
              <Pie options={optionsP1} data={this.state.pieChart1} />
            </div>
            <div className='col col-lg-6 col-md-12'>
              <Pie options={optionsP2} data={this.state.pieChart2} />
            </div>
          </div>
        </div>

    return (
      <div>
        { chart }
      </div>
    );
  }

  populateSalesData = async () => {
    const response = await fetch(`/json/1052-salesman.json`);
    const reciepts = await response.json();
    //console.log(reciepts);
    let labels = [];
    let dataA = [];
    let dataB = [];
    let dataP1 = [];
    let dataP2 = [];
    reciepts.salesData.forEach(e => {
      if(e[1]>0 || e[2]>0) {
        labels.push(e[0]);
        dataA.push(financial(e[1]));
        dataB.push(financial(e[2]));
        dataP1.push((e[1]*100)/reciepts.thisMonthTotal)
        dataP2.push((e[2]*100)/reciepts.lastMonthTotal)
      }
    });
    this.setState({
      salesData: {
        labels:labels,
        datasets: [
          {
            label: months[dt.getMonth()],
            data: dataA,
            borderColor: 'rgb(255, 0, 0)',
            backgroundColor: "rgba(255, 99, 132, 0.6)",
          },
          {
            label: months[dt.getMonth()-1],
            data: dataB,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.6)',
          }
        ]
      }
      , pieChart1: {
        labels: labels,
        datasets: [{
          label: months[dt.getMonth()],
          data: dataP1,
          backgroundColor: [
            'rgba(255, 99, 132, 0.5)',
            'rgba(54, 162, 235, 0.5)',
            'rgba(255, 26, 86, 0.5)',
            'rgba(255, 0, 0, 0.5)',
            'rgba(75, 26, 192, 0.5)',
            'rgba(153, 26, 255, 0.5)',
            'rgba(255, 159, 64, 0.5)',
            'rgba(255,101,80,0.4)',
            'rgba(100,174,80,0.4)',
            'rgba(100,99,255,0.4)',
            'rgba(48,139,63,0.4)',
            'rgba(92,45,168,0.4)',
          ],
          borderColor: [
            'rgba(255, 255, 255, 0.2  )',
          ],
          borderWidth: 1,
        }]
    }
    , pieChart2: {
      labels: labels,
      datasets: [{
        label: months[dt.getMonth()]-1,
        data: dataP2,
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 26, 86, 0.5)',
          'rgba(255, 0, 0, 0.5)',
          'rgba(75, 26, 192, 0.5)',
          'rgba(153, 26, 255, 0.5)',
          'rgba(255, 159, 64, 0.5)',
        ],
        borderColor: [
          'rgba(255, 255, 255, 0.2  )',
        ],
        borderWidth: 1,
      }]
    }
    , loading: false });
  }


}
