import React, { Component } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export class PieChart extends Component {
    static displayName = PieChart.name;

    constructor(props) {
        super(props);
        this.state = { loaded: true };
    }
    render() {
        return (
            <Pie options={this.props.options} data={this.props.pieChart} />
        )
    }
}