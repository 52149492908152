import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom";
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { SalesChart } from './components/SalesChart';
import { DailySales } from './components/DailySales';
import { SalesmanChart } from './components/SalesmanChart';
import { AreaChart } from './components/AreaChart';
import { TodayChart } from './components/TodayChart';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="sales" element={<SalesChart />} />
                    <Route path="dailysales" element={<DailySales />} />
                    <Route path="salesman" element={<SalesmanChart />} />
                    <Route path="area" element={<AreaChart />} />
                    <Route path="today" element={<TodayChart />} />
                </Routes>
            </Layout>
        );
    }
}
