import React, { Component } from 'react';
const dt = new Date();

export class Footer extends Component {
    static displayName = Footer.name;

    render() {
        return (
            <footer>
                <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                    © {dt.getFullYear()} Copyright: &nbsp; 
                    <a className='text-reset fw-bold' href='https://femco.ae/'>
                        FEMCO.ae
                    </a>
                </div>
            </footer>
        )
    }
}