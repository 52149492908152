import React, { Component } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { Loader } from './Loader';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'November', 'December'];
const date = new Date();
const financial = (x) => Number.parseFloat(x).toFixed(2);

const findByDay = (e, day) => {
    for (let o of e) {
        if (o.day === day) {
            return financial(o.amount);
        }
    }
    return 0;
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export const options = {
    responsive: true,
    plugins: {
        datalabels: {
            display: false,
            color: "black",
            align: "end",
            anchor: "end",
            font: { size: "11" },
            formatter: function(value, context) {
              return Math.round(value*100)/100;
            }
        },
        legend: {
            position: 'bottom',
        },
        title: {
            display: true,
            text: 'Sales by Month ' + date.getFullYear(),
        },
    },
};

export class DailySales extends Component {
    static displayName = DailySales.name;

    constructor(props) {
        super(props);
        this.state = {
            salesData: [],
            loading: true,
            createdAt: ''
        };

    }
    componentDidMount() {
        this.populateSalesData();
    }

    render() {
        let created = this.state.loading
                ? <p>&nbsp;</p>
                : <p className='mt-5 text-muted small'>Data Updated at {this.state.createdAt}</p>

        let chart = this.state.loading
            ? <Loader />
            : <Bar options={options} data={this.state.salesData} />

        return (
            <div>
                {chart}
                { created }
            </div>
        );
    }

    populateSalesData = async () => {
        const response = await fetch(`./json/1052-monthly.json`);
        const reciepts = await response.json();
        //console.log(reciepts);
        let createdAt = reciepts.createdAt;
        let labels = [];
        let dataA = [];
        let dataB = [];
        for (let x = 1; x <= date.getDate(); x++) {
            labels.push('Day '+x)
            dataA.push(findByDay(reciepts.thisMonth, x));
            dataB.push(findByDay(reciepts.lastMonth, x));
            //console.log(x + ' : ' + findByDay(reciepts.salesInvoice, x));
        }
        this.setState({
            salesData: {
                labels: labels,
                datasets: [
                    {
                        label: months[date.getMonth()],
                        data: dataA,
                        borderColor: 'rgb(255, 0, 0)',
                        backgroundColor: "rgba(255, 99, 132, 0.6)",
                    },
                    {
                        label: months[date.getMonth() - 1],
                        data: dataB,
                        borderColor: 'rgb(0, 0, 255)',
                        backgroundColor: 'rgba(53, 162, 235, 0.6)',
                    }
                ]
            }
            , loading: false
            , createdAt: createdAt
        });
    }
}
