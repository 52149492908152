import React, { Component } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line, Bar } from 'react-chartjs-2';
import { Loader } from './Loader';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'November', 'December'];
const dt = new Date();

const findByDay = (e, day) => {
    for (let o of e) {
        if (o.day === day) {
            return o.amount;
        }
    }
    return 0;
}

const findByDay1 = (e, day) => {
    for (let o of e) {
      if(o.day === day) {
        return o.count; 
      }
    }
    return 0;
}

export const options = {
    responsive: true,
    plugins: {
        datalabels: {
            display: false,
            color: "black",
            align: "end",
            anchor: "end",
            font: { size: "11" },
            formatter: function(value, context) {
              return Math.round(value*100)/100;
            }
        },
        legend: {
            position: 'bottom',
        },
        title: {
            display: true,
            text: 'Sales of the month - ' + months[dt.getMonth()] + ', ' + dt.getFullYear()
        },
    },
};

export const options1 = {
    responsive: true,
    plugins: {
        datalabels: {
            display: false,
            color: "black",
            align: "end",
            anchor: "end",
            font: { size: "11" },
            formatter: function(value, context) {
              return Math.round(value*100)/100;
            }
          },
        legend: {
            position: 'bottom',
        },
        title: {
            display: true,
            text: 'No of Invoices - ' + months[dt.getMonth()] + ', ' + dt.getFullYear()
        },
    },
};

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            salesData: [],
            invCount: [],
            loading: true,
            createdAt: ''
        };

    }
    componentDidMount() {
        this.populateSalesData();
    }

    render() {
        let created = this.state.loading
                ? <p>&nbsp;</p>
                : <p className='mt-5 text-muted small'>Data Updated at {this.state.createdAt}</p>

        let chart = this.state.loading
            ? <Loader />
            :   <div>
                    <div className='row'>
                        <div className='col col-md-12'>
                            <Line options={options} data={this.state.salesData}/> 
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col col-md-12 mt-5'>
                            <Bar options={options1} data={this.state.invCount}/>
                        </div>
                    </div>
                </div>

        return (
            <div>
                {chart}
                {created}
            </div>
        );
    }

    populateSalesData = async () => {
        const response = await fetch('./json/salesofthemonth.json');
        const reciepts = await response.json();
        //console.log(reciepts);
        let createdAt = reciepts.createdAt;
        let labels = [];
        let dataA = [];
        let dataB = [];
        let countA = [];
        let countB = [];
        let month = months[dt.getMonth()].substring(0, 3);
        for (let x = 1; x <= new Date().getDate(); x++) {
            labels.push(month + ' ' + x)
            dataA.push(findByDay(reciepts.salesInvoice, x));
            dataB.push(findByDay(reciepts.salesOrder, x));
            countA.push(findByDay1(reciepts.salesInvoice, x));
            countB.push(findByDay1(reciepts.salesOrder, x));
         }

        this.setState({
            salesData: {
                labels: labels,
                datasets: [
                    {
                        label: "Sales Invoice",
                        data: dataA,
                        borderColor: 'rgb(255, 0, 0)',
                        backgroundColor: "rgba(255, 99, 132, 0.6)",
                    },
                    {
                        label: "Sales Order",
                        data: dataB,
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.6)',
                    }
                ]
            }
            , 
            invCount: {
              labels:labels,
              datasets: [
                {
                  label: "Sales Invoice",
                  data: countA,
                  borderColor: 'rgb(255, 0, 0)',
                  backgroundColor: "rgba(255, 99, 132, 0.8)",
                },
                {
                  label: "Sales Order",
                  data: countB,
                  borderColor: 'rgb(53, 162, 235)',
                  backgroundColor: 'rgba(53, 162, 235, 0.8)',
                }
              ]
            }
            , loading: false
            , createdAt: createdAt
        });
    }



}
