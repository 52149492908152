import React, { Component } from 'react';

export class Loader extends Component {
    static displayName = Loader.name;

    render() {
        return (
            <div className="text-center">
                <div className="spinner-border text-primary spinner-size mt-5" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }
}